<template>
    <div class="vx-row mt-3">
        <DataTable :tab="this.tab" />
    </div>
</template>
<script>
import DataTable from '../data-table'

export default {
    props: ['tab'],
    components: {
        DataTable
    },
}
</script>